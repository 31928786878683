// i18next-extract-mark-ns-start payment-gateway

import {Button, ButtonLink} from 'components/Button';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {HomePartners} from 'components/HomePartners';
import {HomePaymentMethods} from 'components/HomePaymentMethods';
import 'keen-slider/keen-slider.min.css';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql, PageProps} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import index1 from 'images/payment_gateway.svg';
import payment from 'images/payment.svg';
import React from 'react';
import styled from 'styled-components';
import {AnchorLink} from 'components/AnchorLink';
import {Clients, defaultClientList} from 'components/Clients';
import CardsContainer from 'components/CardsContainer';
import {Card} from 'components/Card';
import {StyledDiv, StyledP} from 'components/StyledSx';
import {ScreenSizes} from '../../../types/responsive';
import {BlogLink} from 'components/links/Blog';
import {InternalPageLink} from 'components/links/Pages';
import {GoogleReviews, Place} from 'components/GoogleReviews';
import {Background} from 'components/Background';
import {SupportLink} from 'components/links/Support';
import megaphone from 'images/megaphone.svg';
import trading from 'images/subscriptions_reasons_3.svg';
import coinstack from 'images/coinstack.svg';
import click1 from 'images/click1.svg';
import shopify_payment_gateway from 'images/shopify_payment_gateway.svg';
import recurring_payments from 'images/recurring_payments.svg';
import {
  GradientBox,
  GradientCtaImage,
  GradientSectionDescription
} from 'components/landings/GradientBox';
import integrated_payments_header_image from 'images/integrated_payments_header_image.png';
import {FaqsSection} from 'components/FaqsSection';
import {List} from 'components/List';
import Bold from 'components/Bold';
import {LearnMoreLinks} from 'components/LearnMoreLinks';

const CardWrapper = styled.div`
  max-width: 230px;
`;

const CardImage = styled.img`
  position: absolute;
`;

const IndexBackground = styled.div`
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: -8.9%;
    height: 1256px;
    width: 1203px;
    background: linear-gradient(353.74deg, #41efd6 -47.57%, #aab4e9 13.85%);
    transform: matrix(2, 0, 0, -1, 1248, 4) rotate(47deg) skew(7deg, 1.6deg);
    @media (max-width: 1024px) {
      top: -11.5%;
      transform: matrix(2, 0, 0, -1, 1185, 25) rotate(44.5deg) skew(7deg, 1.6deg);
    }
    @media (max-width: 768px) {
      content: none;
    }
  }

  &:after {
    display: block;
    content: '';
    position: absolute;
    transform: rotate(34deg) matrix(1, 0, 0, -1, 459, -315);
    left: 50%;
    top: -3%;
    opacity: 1;
    height: 972px;
    width: 1289px;
    background: linear-gradient(353.74deg, #41efd6 -41.57%, #aab4e9 53.85%);
    @media (max-width: 1024px) {
      top: -13%;
      transform: rotate(34deg) matrix(1, 0, 0, -1, 470, -240);
    }
    @media (max-width: 768px) {
      content: none;
    }
  }
`;

const IndexImage = styled.img`
  position: absolute;
  left: 56%;
  top: 20px;
  width: 540px;
  z-index: 9;

  @media (max-width: 1024px) {
    left: 60%;
    width: 45%;
    top: 1%;
  }
`;

const IndexContent = styled.div`
  padding-top: 100px;
  padding-bottom: 0;
  max-width: 550px;
  min-height: 600px;
  @media (max-width: ${ScreenSizes.md}) {
    max-width: 500px;
    min-height: 510px;
  }
  @media (max-width: ${ScreenSizes.sm}) {
    max-width: 100%;
    min-height: 410px;
  }
`;

const ReviewsBackground = styled.div`
  position: relative;
  margin-top: 100px;

  &:before {
    display: block;
    content: '';
    z-index: -1;
    height: 70%;
    position: absolute;
    transform: skew(0, -9deg);
    left: 0;
    right: 0;
    bottom: 0;
    top: 20%;
    background: linear-gradient(96.34deg, #00e9d5 0%, #aab4e9 100%);
  }
`;

const PaymentGateway: React.FC<PageProps> = (props) => {
  const {navigate, t} = useI18next();

  const {data} = props;
  const place: Place = {
    rating: data.googlePlacesPlace.rating,
    user_ratings_total: data.googlePlacesPlace.user_ratings_total,
    reviews: data.allGooglePlacesReview.nodes
  };

  const clientsList = defaultClientList.slice(0, 5).map((client) => {
    return {
      ...client,
      width: 180 * client.scale
    };
  });

  const faqs = [
    {
      header: t('What is a payment gateway?'),
      text: t(
        'Payment gateways are safe and secure payment technology that let you accept <InternalPageLink slug="online-payments">online payments</InternalPageLink> via' +
          ' credit card and other payment methods like Apple Pay, Google Pay, and Bizum.'
      ),
      content: (
        <Trans>
          Payment gateways are safe and secure payment technology that let you accept online
          payments via credit card and other payment methods like Apple Pay, Google Pay, and Bizum.
        </Trans>
      )
    },
    {
      header: t('How does a payment gateway work?'),
      text: t(`A payment gateway integrates with your e-commerce website and communicates with the acquirer, issuer,
       and processor during online transactions to approve or decline payments. It then deposit funds for approved transactions into your bank account.`),
      content: (
        <Trans>
          A payment gateway integrates with your e-commerce website and communicates with the
          acquirer, issuer, and processor during online transactions to approve or decline payments.
          It then deposit funds for approved transactions into your bank account.
        </Trans>
      )
    },
    {
      header: t('Why do I need a payment gateway?'),
      text: t(
        'Without an online payment gateway, you’ll have no way of securely accepting payments in your e-commerce store.'
      ),
      content: (
        <Trans>
          Without an online payment gateway, you’ll have no way of securely accepting payments in
          your e-commerce store.
        </Trans>
      )
    },
    {
      header: t('What are the types of payment gateways?'),
      text: t(
        `- Hosted payment gateway. - Self-hosted payment gateway. - API-hosted or integrated payment gateway. - Local bank integration gateway.`
      ),
      content: (
        <List sx={{marginTop: '12px'}}>
          <Trans parent="li">Hosted payment gateway</Trans>
          <Trans parent="li">Self-hosted payment gateway</Trans>
          <Trans parent="li">API-hosted or integrated payment gateway</Trans>
          <Trans parent="li">Local bank integration gateway</Trans>
        </List>
      )
    }
  ];

  const schemaFaqs = {
    '@type': 'FAQPage',
    mainEntity: faqs.map((faq) => ({
      '@type': 'Question',
      name: faq.header,
      acceptedAnswer: {
        '@type': 'Answer',
        text: faq.text
      }
    }))
  };

  return (
    <>
      <SEO
        title={t('Payment Gateway - Accept Payments Online')}
        description={t(
          'MONEI’s payment gateway is secure, affordable, and easily integrates with your e-commerce website. Accept more payment methods and increase sales. Start now ››'
        )}
        schema={schemaFaqs}
      />
      <IndexBackground>
        <Content>
          <IndexContent>
            <SectionHeader underline tagName="h1">
              <Trans>The best payment gateway to grow your online business</Trans>
            </SectionHeader>
            <Trans parent="p">
              Accept online payments from consumers in Spain and across borders. Choose MONEI for
              one e-commerce payment gateway integration that let’s you accept all the most
              important payment methods including cards, digital wallets, and local payment methods.
            </Trans>
            <SectionActions>
              <SignUpButton variant="light" style={{marginRight: 15}}>
                <Trans>Open an Account</Trans>
              </SignUpButton>
              <ContactSalesButton />
            </SectionActions>
            <StyledP
              sx={{color: '#74858E', marginTop: '16px', fontSize: '14px', fontStyle: 'italic'}}>
              <Trans>
                *Currently supporting merchants in Spain and{' '}
                <InternalPageLink slug="andorra-payment-gateway">Andorra</InternalPageLink>
              </Trans>
            </StyledP>
          </IndexContent>
        </Content>
        <IndexImage
          src={index1}
          alt="MONEI payment gateway"
          title="MONEI payment gateway"
          className="hide-on-mobile"
        />
        <Content sx={{marginTop: '90px'}}>
          <Clients clientList={clientsList} />
        </Content>
      </IndexBackground>
      <Content>
        <Section textAlign="center">
          <div>
            <div style={{marginBottom: 122}}>
              <SectionHeader underline>
                <Trans>Accept more payment methods using one payment gateway</Trans>
              </SectionHeader>
              <Trans parent="p" style={{margin: 'auto', marginBottom: 18}}>
                Increase conversions, reach more customers, and improve the payment experience by
                accepting popular{' '}
                <InternalPageLink slug="payment-methods">payment methods</InternalPageLink> like
                credit and debit{' '}
                <InternalPageLink slug="payment-methods/credit-cards">cards</InternalPageLink>,{' '}
                <InternalPageLink slug="payment-methods/google-pay">Google Pay</InternalPageLink>,{' '}
                <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink>, and{' '}
                <BlogLink slug="local-payment-methods">local payment methods</BlogLink> inluding{' '}
                <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink>,{' '}
                <InternalPageLink slug="payment-methods/multibanco">Multibanco</InternalPageLink>,{' '}
                and more.
              </Trans>
              <StyledDiv sx={{marginTop: '30px'}}>
                <HomePaymentMethods />
                <Button variant="dark" onClick={() => navigate('/payment-methods/')}>
                  <Trans>View All Payment Methods</Trans>
                </Button>
              </StyledDiv>
            </div>
          </div>
        </Section>
      </Content>
      <Content>
        <Section textAlign="center" sx={{paddingTop: '0'}}>
          <div
            style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '16px'}}>
            <SectionHeader underline>
              <Trans>Quick and easy payment gateway integration</Trans>
            </SectionHeader>
            <Trans parent="p" style={{margin: 'auto'}}>
              Seamlessly connect your e-commerce website with MONEI’s payment gateway. Use plugins
              for <InternalPageLink slug="shopify-payment-gateway">Shopify</InternalPageLink>,{' '}
              <InternalPageLink slug="woocommerce-payment-gateway">WooCommerce</InternalPageLink>,{' '}
              <InternalPageLink slug="wix-payment-gateway">Wix</InternalPageLink>,{' '}
              <InternalPageLink slug="magento-payment-gateway">
                Magento (Adobe Commerce)
              </InternalPageLink>
              , and{' '}
              <InternalPageLink slug="prestashop-payment-gateway">PrestaShop</InternalPageLink> or
              integrate with a custom-built website using our{' '}
              <AnchorLink href="https://docs.monei.com/api/">payments API</AnchorLink>.
            </Trans>
            <HomePartners />
            <SignUpButton variant="light">
              <Trans>Open an Acount</Trans>
            </SignUpButton>
          </div>
        </Section>
      </Content>
      <Background>
        <Content>
          <Section centered sx={{paddingTop: {sm: '100px'}}}>
            <StyledDiv>
              <SectionHeader>
                <Trans>Payment gateway security and compliance</Trans>
              </SectionHeader>
              <Trans parent="p">
                Security is key. Your payment gateway shouldn’t be a weak link in the transaction
                process — and it should help you avoid fraud and chargebacks.
              </Trans>
              <Trans>
                Our payment gateway is{' '}
                <BlogLink slug="what-is-3d-secure-and-its-advantages-for-e-commerce">
                  3D Secure 2.0
                </BlogLink>
                , <BlogLink slug="psd2">PSD2</BlogLink>, and{' '}
                <BlogLink slug="understanding-pci-compliance">PCI DSS Level 1</BlogLink> compliant —
                you’ll never have to worry about payment security issues.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark">
                  <Trans>Get Started</Trans>
                </SignUpButton>
              </SectionActions>
            </StyledDiv>
            <SectionImage
              style={{width: '70%'}}
              mobileWidth={370}
              src={shopify_payment_gateway}
              alt="Scan to Pay"
              title="Scan to Pay"
              className="hide-on-mobile"
            />
          </Section>
        </Content>
      </Background>
      <Content>
        <Section column centered sx={{textAlign: 'center'}}>
          <SectionHeader underline>
            <Trans>Why choose MONEI as your online payment gateway?</Trans>
          </SectionHeader>
          <Trans parent="p">
            Skip the headache of getting a virtual POS from your bank. Integrate with MONEI for{' '}
            <BlogLink slug="accepting-credit-card-payments-without-merchant-account">
              built-in merchant acquiring
            </BlogLink>{' '}
            and the ability to accept{' '}
            <BlogLink slug="cross-border-payments">cross-border payments</BlogLink>. Onboard in five
            minutes to enjoy these features and more.
          </Trans>
          <SectionActions>
            <SignUpButton variant="light" style={{marginRight: 15}}>
              <Trans>Open an Account</Trans>
            </SignUpButton>
            <ContactSalesButton />
          </SectionActions>
          <CardsContainer sx={{marginTop: '60px'}}>
            <Card>
              <CardImage src={trading} alt="" style={{width: 100, top: -35}} />
              <CardWrapper>
                <Trans parent="p">Manage your entire payment stack from a single platform</Trans>
              </CardWrapper>
            </Card>
            <Card>
              <CardImage src={megaphone} alt="" style={{width: 105, top: -40}} />
              <CardWrapper>
                <Trans parent="p">
                  Boost trust and brand awareness with a{' '}
                  <SupportLink slug="/categories/360003383258">customized</SupportLink> checkout
                  page
                </Trans>
              </CardWrapper>
            </Card>
            <Card>
              <CardImage src={payment} alt="" style={{width: 105, top: -40}} />
              <CardWrapper>
                <Trans parent="p">
                  Maximize transaction acceptance rates with{' '}
                  <InternalPageLink slug="features/payments-orchestration">
                    payments orchestration
                  </InternalPageLink>
                </Trans>
              </CardWrapper>
            </Card>
            <Card>
              <CardImage src={coinstack} alt="" style={{width: 95, top: -40}} />
              <CardWrapper>
                <Trans parent="p">
                  Save money on transaction fees with{' '}
                  <InternalPageLink slug="pricing">dynamic pricing</InternalPageLink> and enjoy fast
                  settlements
                </Trans>
              </CardWrapper>
            </Card>
            <Card>
              <CardImage src={recurring_payments} alt="" style={{width: 110, top: -40}} />
              <CardWrapper>
                <Trans parent="p">
                  Earn recurring revenue with flexible{' '}
                  <InternalPageLink slug="features/recurring-payments">
                    subscription plans
                  </InternalPageLink>
                </Trans>
              </CardWrapper>
            </Card>
            <Card>
              <CardImage src={click1} alt="" style={{width: 105, top: -40}} />
              <CardWrapper>
                <Trans parent="p">
                  Take payments with or without a website using{' '}
                  <InternalPageLink slug="features/pay-by-link">Pay By Link</InternalPageLink>
                </Trans>
              </CardWrapper>
            </Card>
          </CardsContainer>
        </Section>
      </Content>
      <ReviewsBackground>
        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
          <SectionHeader underline sx={{textAlign: 'center'}}>
            <Trans>What MONEI merchants are saying</Trans>
          </SectionHeader>
        </div>
        <GoogleReviews place={place} />
      </ReviewsBackground>
      <Content sx={{marginTop: '100px'}}>
        <Section column centered sx={{textAlign: 'center'}}>
          <SectionHeader underline>
            <Trans>Learn more about MONEI’s payment gateway</Trans>
          </SectionHeader>
          <LearnMoreLinks />
        </Section>
      </Content>
      <Content>
        <GradientBox wrapperStyle={{overflow: 'visible', marginTop: '60px'}}>
          <SectionHeader tagName="h3" underline style={{color: 'white'}}>
            <Trans>Do you have a business platform or marketplace?</Trans>
          </SectionHeader>
          <GradientSectionDescription>
            <Trans parent="p">
              Whether you have a platform or marketplace for brands, restaurants, freelancers,
              taxis, or another industry, use <Bold>MONEI Connect</Bold> to integrate payments and
              help your users accept more payment methods online, in person, or on the go.
            </Trans>
          </GradientSectionDescription>
          <SectionActions>
            <ButtonLink href="/connect/" variant="dark">
              <Trans>Discover MONEI Connect</Trans>
            </ButtonLink>
          </SectionActions>
          <GradientCtaImage
            sx={{
              maskImage: 'linear-gradient(180deg, #000000 94%, rgba(0, 0, 0, 0) 100%)',
              bottom: '0',
              right: '70px',
              width: '25%'
            }}
            src={integrated_payments_header_image}
          />
        </GradientBox>
      </Content>
      <Content>
        <Section centered column>
          <FaqsSection faqs={faqs} title={<Trans>Payment gateway FAQ</Trans>} />
        </Section>
      </Content>
    </>
  );
};

export default PaymentGateway;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "payment-gateway"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    googlePlacesPlace {
      user_ratings_total
      rating
    }
    allGooglePlacesReview(sort: {fields: rating, order: DESC}, filter: {rating: {gt: 2}}) {
      nodes {
        id
        rating
        text
        relative_time_description
        profile_photo_url
        author_name
      }
    }
  }
`;
